@keyframes secIcon {
  0% {
    transform: translateY(-.15rem);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 0.2;
  }
}

@keyframes skew{
  0% {
    transform: skewX(-45deg);
    opacity: 0;
  }
  100% {
    transform: skewX(0);
    opacity: 1;
  }
}

@keyframes up{
  0% {
    transform: translateY(.7rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(-180deg);
    opacity: 0
  }
  100%{
    transform: translate(.48rem, -.84rem) rotateY(0);
    opacity: 1;
  }
}

@keyframes width {
  0% {
    width: 0;
    opacity: 0
  }
  100%{
    width: .5rem;
    opacity: 1;
  }
}

@keyframes titleWidth {
  0% {
    width: 0;
    opacity: 0
  }
  100%{
    width: .77rem;
    opacity: 1;
  }
}

@keyframes left {
  0% {
    transform: translateX(-.5rem);
    opacity: 0
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes x{
  0% {
    top: 20%;
    height: 0;
  }
  100%{
    top: 50%;
    height: .1rem;
  }
}

@keyframes y{
  0% {
    right: -.2rem;
    width: .4rem;
  }
  100%{
    right: .26rem;
    width: .1rem;
  }
}

@keyframes xx{
  0% {
    top: 50%;
    height: .1rem;
  }
  100%{
    top: 20%;
    height: 0;
  }
}

@keyframes yy{
  0% {
    right: .26rem;
    width: .1rem;
  }
  100%{
    right: -.2rem;
    width: .4rem;
   
  }
}

@keyframes down {
  0% {
    transform: translateY(-.15rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes upBig {
  0% {
    height: 0;
  }
  100% {
    height: .23rem;
  }
}
