@import '../../../styles/var.less';
.footer{
  border-top: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
  padding: .35rem 0 .42rem;
  &-container{
    display: flex;
    align-items: flex-start;
  }
  &-main{
    flex: 1;
    display: flex;
  }
  &-item{
    color: @more-text-color;
    font-size: .16rem;
    &_title{
      color: @grey-color-2;
      display: inline-block;
      padding: 0 .32rem .2rem 0;
      border-bottom: 1px solid @primary-color;
    }
    a, p{
      font-size: 12px;
      color: @more-text-color;
      display: block;
      margin-top: .2rem;
      &:hover{
        color: @primary-color;
        position: relative;
      }
    }
    p{
      cursor: pointer;
      &.weixin:hover:after{
        content: '';
        display: block;
        background: url(../../../assets/images/about/wechat.jpg) center no-repeat;
        background-size: cover;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        right: 0;
        transform: translate(110%, -50%);
      }
    }
  }
  &-item:not(:last-child){
    margin-right: 1.71rem;
  }
  &-code{
    text-align: center;
    font-size: 12px;
    color: @more-text-color;
    img{
      width: 1.5rem;
      margin-bottom: .15rem;
    }
  }
}
.copyright{
  display: flex;
  align-items: center;
  height: .85rem;
  color: @more-text-color;
  letter-spacing: 1px;
  >div{
    font-size: 12px;
  }
  &-address{
    flex: 1;
    margin-right: .1rem;
    display: flex;
    align-items: center;
    img{
      width: .15rem;
      margin-right: .05rem;
    }
  }
  .space{
    margin: 0 .06rem;
  }
}
@media screen and (max-width: 1370px) {
  .subAddress{
    display: inline-block;
  }
  .space{
    display: none;
  }
  .address{
    margin-left: .1rem;
  }
}




@primary-color: #c9a062;