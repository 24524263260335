@import './var.less';

/* mainColor: #c9a062 */
body {
  padding: 0px;
  margin: 0px;
  min-width: 12rem;
  font-size: .14rem;
  img {
    width: 100%;
    height: auto;
  }
  p {
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.5;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
    margin-bottom: 0;
    list-style: none;
  }
  div{
    box-sizing: border-box;
  }
}

@primary-color: #c9a062;