// @import '~antd/dist/antd.less';
html{
  font-size: 100px;
  color: #000;
}
.common-container{
  width: 12rem;
  margin: 0 auto;
  font-size: .14rem;
};

@primary-color: #c9a062;