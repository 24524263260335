@import '../../../styles/var.less';
@import '../../../styles/animate.less';
.header{
  .logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
    &-icon{
      img{
        height: .6rem;
        width: auto;
      }
      padding: .12rem 0;
    }
    &-text{
      img{
        height: .14rem;
        width: auto;
      }
      margin-bottom: .15rem;
    }
  }
  .nav{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: #fff;
    padding: 0 .4rem;
    // border-bottom: 1px solid @grey-color-6;
    border-top: 1px solid @grey-color-6;
    &.fixed{
      position: fixed;
      width: 100%;
      top: 0px;
      animation: nav 1s;
      animation-fill-mode: forwards;
      padding-right: 0;
      box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
      0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);;
      .nav-container{
        height: .7rem;
        .anticon{
          line-height: .7rem;
        }
      }
      li{
        width: 1.86rem;
      }
    }
    &-logo{
      flex: 1;
      display: flex;
      align-items: center;
      &_img{
        width: .44rem;
        margin-right: .1rem;
      }
      &_title{
        width: 1.08rem;
      }
    }
    &-container{
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      color: @grey-color-1;
      height: .6rem;
      li{
        height: 100%;
        width: 1.76rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        &:hover, &.active{
          background-color: @primary-color;
          color: #fff;
          transition: background-color .5s linear;
        }
        img{
          width: .18rem;
          line-height: .6rem;
        }
      }
      li:not(:first-child) {
        border-left: 1px solid @nav-border-color;
      }
    }
    &-item{
      &_alias{
        font-size: .16rem;
        margin-top: -.06rem;
      }
    }
    &-btn{
      flex: 1;
      text-align: center;
      line-height: .5rem;
    }
  }
  .keep {
    height: .5rem;
  }
}



@keyframes nav {
  0% {
    transform: translateY(-.5rem);
  }
  100% {
    transform: translateY(0px);
  }
}

@primary-color: #c9a062;